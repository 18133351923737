<template>
  <div class="pc-video">
    <div class="cover" @click="toWatch()">
      <div class="kv">
        <img :src="data.kvUrl" alt="" />
      </div>
      <div class="model"></div>
      <div class="info">
        <div class="channel-name">{{ data.channelName }}</div>
        <div v-if="data.liveStatus == 0" class="time">{{ data.liveTime }}</div>
      </div>
      <div class="play-icon">
        <img src="../assets/ico.png" alt="" />
      </div>
      <div class="status-icon" v-if="data.type == 0">
        <img v-if="data.liveStatus == 0" src="../assets/start2.png" alt="" />
        <img
          v-else-if="data.liveStatus == 1"
          src="../assets/nowLive2.png"
          alt=""
        />
      </div>
    </div>
    <div class="course-info">
      <h3 class="title hidden2" @click="toWatch()">{{ data.subject }}</h3>
      <div class="lecturer">
        <p v-for="(item, i) in data.liveLecturerListMap.slice(0, 2)" :key="i">
          <i>
            <img src="../assets/lecturer.png" alt="" />
          </i>
          <span class="lecturer-name">{{ item.lecturerName }}</span>
          <span class="lecturer-organization">{{ item.companyName }}</span>
        </p>
      </div>
    </div>
    <div class="information">
      <div class="course-btn">
        <button
          :class="['collect-btn', data.ymnCollect != null ? 'active' : '']"
          @click="collectFn()"
        >
          {{ data.ymnCollect != null ? "已收藏" : "收藏" }}
        </button>
        <button
          v-if="data.liveStatus == 0 && data.type == 0"
          :class="['apply-btn', data.ymnSubscribe != null ? 'active' : '']"
          @click="appointmentFn()"
        >
          {{ data.ymnSubscribe != null ? "已预约" : "预约" }}
        </button>
      </div>
      <span
        class="num-data"
        v-if="
          data.liveStatus == 0 && data.type == 0 && data.applySumStatus == 1
        "
        ><span class="bar"></span
        >{{
          `预约人数 ${parseInt(data.applySum) + data.virtualApplySum}人`
        }}</span
      >
      <span
        class="num-data"
        v-if="(data.liveStatus != 0 || data.type == 1) && data.pvStatus == 1"
        ><span class="bar"></span
        >{{ `观看人数 ${data.viryualPv + data.watchNumber}人` }}</span
      >
      <!-- <span>{{
        data.liveStatus == 0 && data.type == 0
          ? `预约人数 ${data.virtualApplySum}人`
          : `观看人数 ${data.viryualPv}人`
      }}</span> -->
    </div>
  </div>
</template>

<script>
import { collect, unCollect, appointment, unAppointment } from "../api/user";
export default {
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    // 收藏
    collectFn() {
      if (this.data.ymnCollect != null) {
        // 已收藏
        unCollect({
          id: this.data.ymnCollect.id,
          courseId: this.data.id,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("已取消收藏");
            this.$emit("refDataList");
            // this.collectStatus = null;
          }
        });
      } else {
        // 收藏
        collect({
          courseId: this.data.id,
          // userId: this.userId,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("收藏成功");
            this.$emit("refDataList");
            // this.collectStatus = 1;
          } else {
            this.$router.push({ path: "/login" });
          }
        });
      }
    },
    // 预约
    appointmentFn() {
      if (this.data.ymnSubscribe != null) {
        // 已预约
        unAppointment({
          id: this.data.ymnSubscribe.id,
          courseId: this.data.id,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("已取消预约");
            this.$emit("refDataList");
            // this.appointmentStatus = null;
          }
        });
      } else {
        // 预约
        appointment({
          courseId: this.data.id,
          // userId: this.userId,
          type: "",
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("预约成功");
            this.$emit("refDataList");
            // this.appointmentStatus = 1;
          } else {
            this.$router.push({ path: "/login" });
          }
        });
      }
    },
    toWatch() {
      let scourceTag = this.$route.query.st ? '?st=' + this.$route.query.st : ''
      this.$router.push({ path: "/watch/" + this.data.id + scourceTag });
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  display: block;
}
h3,
p {
  margin: 0;
}
.pc-video {
  width: 390px;
  height: 445px;
  background-color: #fff;
  .cover {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 212px;
    overflow: hidden;
    .model {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.1);
      // z-index: 1;
    }
    .info {
      box-sizing: border-box;
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 40px;
      padding: 0 20px;
      color: #fff;
      font-size: 16px;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.8)
      );
      .channel-name {
        font-weight: bold;
      }
    }
    .play-icon {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 64px;
      height: 64px;
    }
    .status-icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 100px;
      height: 100px;
    }
  }
  .course-info {
    box-sizing: border-box;
    height: 136px;
    border-bottom: 1px solid #ececec;
    .title {
      font-size: 22px;
      padding: 0 20px;
      text-align: justify;
      margin-top: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      // white-space: nowrap;
      cursor: pointer;
    }
    .lecturer {
      padding: 0 20px;
      p {
        margin-top: 8px;
        display: flex;
        align-items: center;
        i {
          display: inline-block;
          width: 16px;
          height: 16px;
        }
        .lecturer-name {
          margin: 0 15px;
        }
        .lecturer-organization {
          color: #999;
        }
      }
    }
  }
  .information {
    height: 78px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    span {
      font-size: 14px;
      color: #999;
    }
    .course-btn {
      button {
        height: 36px;
        padding: 0 25px;
        border-radius: 18px;
        font-weight: bold;
        margin-left: 10px;
        border: 1px solid #49b67b;
        cursor: pointer;
      }
      button.collect-btn {
        color: #49b67b;
        background-color: #fff;
      }
      button.apply-btn {
        background-color: #49b67b;
        color: #fff;
      }
      button.active {
        border: 1px solid #ececec;
        color: #999;
        font-weight: normal;
        background-color: #fff;
      }
    }
  }
}
</style>
